import Link from "next/link";
import React from "react";
import VoiceImage from "../VoiceImage";
import { FaHeart } from "react-icons/fa";
import { PiMicrophoneStageFill } from "react-icons/pi";

const CollectionCard = ({ collection }) => {
  return (
    <Link
      href={`/collection/${collection._id}`}
      className=" tw-flex tw-relative tw-min-w-60 tw-h-32 tw-flex-col tw-items-center tw-justify-center tw-rounded-2xl tw-bg-transparent tw-p-2"
      key={collection._id}
    >
      <div className="tw-absolute tw-z-20 tw-bottom-0 tw-left-0 tw-w-full tw-h-full tw-bg-gradient-to-t tw-from-black/100 tw-to-transparent tw-rounded-b-3xl" />

      <VoiceImage
        model={collection}
        className="tw-rounded-3xl"
        fill={true}
        style={{ objectFit: "cover" }}
        sizes="(max-width: 710px) 50vw,
      (max-width: 910px) 35vw,
      (max-width: 1200px) 20vw,
      (max-width: 1400px) 15vw,
      15vw"
        quality={75}
        priority={false}
        alt={`${collection.title} Image`}
        size={null}
      />
      <div className="tw-absolute tw-bottom-0 tw-left-0 tw-h-full tw-w-full tw-flex tw-flex-col tw-text-white tw-z-30 tw-rounded-b-2xl tw-p-3">
        <div className="tw-flex tw-items-center tw-gap-2 tw-w-full tw-justify-between">
          <div className="tw-flex tw-items-center tw-gap-1 tw-text-xs tw-font-black tw-bg-black/60 tw-w-max tw-p-1 tw-rounded-full tw-px-2">
            {collection.likes}
            <FaHeart size={12} className="tw-text-red-500" />
          </div>
          <div className="tw-flex tw-items-center tw-gap-1 tw-text-xs tw-font-black tw-bg-black/60 tw-w-max tw-p-1 tw-rounded-full tw-px-2">
            {collection.modelsCount}
            <PiMicrophoneStageFill size={14} />
          </div>
        </div>
        <div className="tw-text-sm tw-font-bold tw-mt-auto">
          {collection.title}
        </div>
        <div className="tw-text-xs tw-line-clamp-2 tw-text-white/80 tw-font-semibold">
          {collection.description}
        </div>
      </div>
    </Link>
  );
};

export default CollectionCard;
