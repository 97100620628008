import { useAuth } from "@/context/AuthProvider";
import glitch from "./assets/glitch.png";
import React, { useEffect, useMemo, useState } from "react";
import Image from "next/image";
import { useQuery } from "react-query";
import axios from "axios";
import { SecondaryButton } from "@jammable/ui-core";
import Link from "next/link";
import { capitalizeFirstLetter } from "@/utils/tools";

const DynamicSection = ({ mobile }) => {
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const { user } = useAuth();

  const fetchDynamicContent = async () => {
    const { data } = await axios.get("/api/home/getDynamicContent");
    return data;
  };

  const { data: dynamicContent = [] } = useQuery(
    "dynamicContent",
    fetchDynamicContent,
  );

  const [fadeState, setFadeState] = useState("in");
  const fadeSpeed = 300; // 500 milliseconds for fade animation, adjust as needed

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused) {
        setFadeState("out");
        setTimeout(() => {
          setCurrentContentIndex(
            (prevIndex) =>
              (prevIndex + 1) % (dynamicContent?.content?.length || 1),
          );
          setFadeState("in");
        }, 200); // Wait for fade out before changing content
      }
    }, 5000); // Change content every 5 seconds

    return () => clearInterval(interval);
  }, [isPaused, dynamicContent?.content?.length]);

  const currentContent = dynamicContent?.content?.[currentContentIndex];

  const [greeting, setGreeting] = useState("");
  const [secondaryGreeting, setSecondaryGreeting] = useState("");
  useEffect(() => {
    const getGreeting = () => {
      const currentHour = new Date().getHours();
      const greetings = [
        ["Hey, early riser", "Morning", "Sup", "Hey there", "What's up"],
        ["Yo", "Hey", "Hi there", "What's good", "Howdy"],
        ["Evening", "Hey you", "What's happening", "Hi", "Hiya"],
        [
          "Still up?",
          "Night owl, huh?",
          "Yo, night person",
          "Can't sleep?",
          "Late night vibes",
        ],
      ];

      let timeIndex;
      if (currentHour >= 5 && currentHour < 12) timeIndex = 0;
      else if (currentHour >= 12 && currentHour < 18) timeIndex = 1;
      else if (currentHour >= 18 && currentHour < 21) timeIndex = 2;
      else timeIndex = 3;

      const randomIndex = Math.floor(
        Math.random() * greetings[timeIndex].length,
      );
      return greetings[timeIndex][randomIndex];
    };
    const getSecondaryGreeting = () => {
      const secondaryGreetings = [
        "What will you create today?",
        "Time to get creative!",
        "Unleash your creativity!",
        "Create something amazing!",
        "Let's make magic happen!",
      ];
      const randomIndex = Math.floor(Math.random() * secondaryGreetings.length);
      return secondaryGreetings[randomIndex];
    };

    setSecondaryGreeting(getSecondaryGreeting()); // Set secondary greeting

    setGreeting(getGreeting());
  }, []);
  const getFilledSegments = (streak) => {
    return streak % 5;
  };

  const currentStreak = user?.streaks?.currentStreak ?? 0;

  return (
    <div
      className={`tw-border-2 ${!user ? "tw-bg-purple-700 tw-text-white tw-p-6 tw-h-auto" : "tw-h-max"} tw-border-solid tw-border-black/20 md:tw-flex  tw-p-4  tw-justify-between tw-w-full tw-rounded-4xl tw-text-black tw-flex tw-items-center tw-gap-3 ${
        mobile
          ? "tw-flex-col tw-w-full md:tw-hidden tw-my-2 tw-mt-4 tw-p-4"
          : " tw-hidden tw-max-w-md "
      }`}
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      {dynamicContent?.content?.length > 0 && !user && (
        <div
          className={`tw-transition-opacity tw-flex tw-flex-col tw-gap-2 tw-h-full ${
            fadeState === "in" ? "tw-opacity-100" : "tw-opacity-0"
          }`}
          style={{ transitionDuration: `${fadeSpeed}ms` }}
        >
          {/* <Image
            src={currentContent?.image}
            width={100}
            height={125}
            alt="Dynamic content"
            className="tw-rounded-4xl"
            style={{
              objectFit: "cover",
            }}
          /> */}
          <div className="tw-flex tw-flex-col  tw-h-full">
            <div className="tw-text-2xl tw-font-black tw-z-10">
              {currentContent?.title}
            </div>
            <div className="tw-text-sm tw-font-semibold tw-z-10">
              {currentContent?.description}
            </div>
            {currentContent?.link && (
              <SecondaryButton
                href={currentContent?.link}
                className={`tw-text-sm tw-w-max tw-mt-auto tw-rounded-full tw-h-10 ${
                  mobile ? "tw-mt-4" : ""
                }`}
              >
                {currentContent?.linkText}
              </SecondaryButton>
            )}
          </div>
        </div>
      )}
      {user && (
        <div className="tw-flex tw-flex-col  tw-h-auto tw-w-full ">
          <div className="tw-flex tw-items-center tw-gap-2">
            <Image
              src={user?.avatar}
              width={55}
              height={55}
              className="tw-rounded-full"
              alt={user?.username}
              as={Link}
              href={`/@${user?.username}`}
            />
            <div>
              <div className="tw-text-xl tw-font-bold tw-z-10">
                {greeting}, {user?.username}
              </div>
              {/* <div className="tw-text-sm tw-font-semibold tw-z-10 ">
                {secondaryGreeting}
              </div> */}
              <div className="tw-text-sm tw-w-max tw-left-center tw-rounded-full tw-font-semibold">
                Current Streak -{" "}
                <span className="tw-font-bold">
                  {currentStreak}{" "}
                  {capitalizeFirstLetter(currentStreak === 1 ? "day" : "days")}{" "}
                  {currentStreak === 0 ? "😢" : "🔥"}
                </span>
              </div>
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-mt-4 tw-gap-1 tw-w-full tw-text-white tw-p-3 tw-rounded-4xl tw-bg-black tw-relative">
            <div className="tw-absolute tw-inset-0 tw-rounded-4xl tw-bg-gradient-to-b tw-from-[#EB7BFF] tw-via-[#D799FF] tw-via-[#C5ADFF] tw-via-[#A3AEFE] tw-via-[#7EB7FF] tw-via-[#72CDFD] tw-to-[#4BF2F6] tw-z-0"></div>
            <div className="tw-absolute tw-inset-[2px] tw-rounded-[30px] tw-bg-black tw-z-10"></div>
            <div className="tw-relative tw-z-20">
              <div className="tw-p-2">
                <div className="tw-text-sm tw-font-black tw-tracking-wide">
                  NEXT REWARD
                </div>
              </div>
              <div className="tw-flex tw-items-center tw-gap-1 tw-p-2 tw-pt-0">
                {[...Array(5)].map((_, index) => (
                  <div
                    key={index}
                    className={`tw-w-full tw-h-1.5 tw-rounded-xl ${
                      index < getFilledSegments(currentStreak)
                        ? "tw-bg-teal-300"
                        : "tw-bg-gray-400"
                    }`}
                  />
                ))}
              </div>
              <div className="tw-text-sm tw-mt-0.5 tw-text-white/80 tw-p-2 tw-pt-0">
                Keep up your streak to earn a{" "}
                <span className="tw-font-bold">free voice pass</span> every 5
                days!
              </div>
              {/* <div className="tw-text-sm tw-text-center tw-font-bold tw-mt-1 tw-text-white">
                Earn a free voice pass every 5 days!
              </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DynamicSection;
